import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ServiceOrderPlanningSideNavAssetTicketService {

	matSidenav: MatSidenav;
	readonly routerOutletReady = new Subject<boolean>();

	constructor(
		private router: Router
	){}

	// routeOutletIsReady(){
	// 	this.routerOutletReady.next(true);
	// }

	// setSidenavData(
	// 	sidenavComponent: ServiceOrderPlanningSideNavAssetTicketComponent,
	// 	activatedRoute: ActivatedRoute,
	// 	ticketId: string,
	// 	resetRouterNavigate = false,
	// 	redirTo: string = null
	// ) {

	// 	const state: TicketSidenavData = {
	// 		ticketId,
	// 		redirTo
	// 	};

	// 	if(resetRouterNavigate){
	// 		this.router.navigate(
	// 			[{ outlets: {assetticketrouteroutlet: [] }}],
	// 			{
	// 				state,
	// 				relativeTo: activatedRoute.parent,
	// 				queryParamsHandling: 'preserve',
	// 				// [todo] remove skiplocation when apply @HostListener to close sidenav when history popState
	// 				skipLocationChange: true
	// 			}
	// 		);
	// 	}

	// 	const subscribe = sidenavComponent.sidenavBaseLoaded.subscribe(status => {
	// 		subscribe.unsubscribe();
	// 		if(status){
	// 			this.router.navigate(
	// 				[{ outlets: {assetticketrouteroutlet: ['asset-ticket'] }}],
	// 				{
	// 					state,
	// 					relativeTo: activatedRoute.parent,
	// 					queryParamsHandling: 'preserve',
	// 					// [todo] remove skiplocation when apply @HostListener to close sidenav when history popState
	// 					skipLocationChange: true
	// 				}
	// 			);
	// 		}
	// 	})


	// }

}
